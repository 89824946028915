









import Vue, { PropType } from 'vue';
import HeaderArrowMenu from '~/components/HeaderArrowMenu.vue';
// @ts-ignore

import { isHomeLink, getRelativeLink, getFullUrl } from '~/utils';
import { HOME_URL, WORDPRESS_API_URL } from '~/constants/config';

export default Vue.extend({
  name: 'NavMenuBelowHeader',

  components: {
    HeaderArrowMenu,
  },

  props: {
    list: {
      type: Array as PropType<Array<MenuItem>>,
      required: true,
    },
  },

  computed: {
    items (): Array<MenuItem> {
      return this.list.map((item) => {
        const result = { ...item };

        result.isInternalUrl = isHomeLink(item.link);
        result.isActive = this.isActive(item.link);
        result.link = result.isInternalUrl
          ? getRelativeLink(item.link)
          : item.link;

        return result;
      });
    },
  },

  methods: {
    onClickMenuItem (item: MenuItem): void {
      this.$sendYandexMetrika({
        level1: 'Клик_Хедер',
        level4: getFullUrl(item.link),
        level5: item.title,
        level6: 'Второй уровень',
      });
    },
    isActive (link: string): boolean {
      const linkWithoutHostName = this.getLinkWithHostName(link);
      return this.$route.path === linkWithoutHostName;
    },
    getLinkWithHostName (link: string): string {
      return link.replace(WORDPRESS_API_URL, '').replace(HOME_URL, '');
    },
  },
});
