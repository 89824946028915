














import Vue, { PropType } from 'vue';

export type PartnerBannerProps = {
  image?: string | null,
  link?: string | null,
};

export default Vue.extend({
  name: 'PartnerBanner',

  props: {
    image: {
      type: String as PropType<PartnerBannerProps['image']>,
      default: null,
    },
    link: {
      type: String as PropType<PartnerBannerProps['link']>,
      default: null,
    },
  },
});
