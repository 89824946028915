
















import Vue, { PropType } from 'vue';
import NavMenuSocialsItem from './NavMenuSocialsItem.vue';
import { getFullUrl } from '~/utils';

export default Vue.extend({
  name: 'NavMenuSocials',
  components: {
    NavMenuSocialsItem,
  },
  props: {
    socialTitle: {
      required: true,
      type: String as PropType<string>,
    },
    socialItems: {
      required: true,
      type: Array as PropType<Array<MenuItemWithIcon>>,
    },
  },
  methods: {
    onClickSocials (socialTitle: string, socialLink: string) {
      this.$sendYandexMetrika({
        level1: 'Клик_Хедер',
        level4: getFullUrl(socialLink),
        level5: socialTitle,
        level6: 'Бургер-меню',
      });
    },
  },
});
