






















import Vue, { PropType } from 'vue';
import TheLink from '~/components/TheLink.vue';
import { HOME_URL } from '~/constants/config';

export default Vue.extend({
  name: 'MascotSpeech',

  components: {
    TheLink,
  },

  props: {
    isShow: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    speechText: {
      type: String as PropType<string>,
      required: true,
    },

    buttonText: {
      type: String as PropType<string>,
      required: true,
    },

    buttonLink: {
      type: String as PropType<string>,
      required: true,
    },
  },

  computed: {
    linkTarget (): '_blank' | undefined {
      // Поведение отличается от обычных ссылок, потому не получится использовать isHomeLink
      // Проверяем название сайта без http\https
      return this.buttonLink.includes(HOME_URL?.split('/')?.[2] ?? '') ? undefined : '_blank';
    },
  },
});
