























import Vue from 'vue';
// @ts-ignore
import vClickOutside from 'v-click-outside';
import { mapActions, mapState } from 'vuex';
import BellNotifications from '../BellNotifications/BellNotifications.vue';
import { EMPTY_NOTIFICATIONS, FETCH_NOTIFICATIONS, RESET_UNVIEWED_NOTIFICATIONS_COUNT_ON_SERVER, SET_NOTIFICATIONS_REQUESTED_ACTION } from '~/constants';

Vue.use(vClickOutside);

export default Vue.extend({
  components: {
    BellNotifications,
  },
  props: {
    isAuthenticated: {
      required: true,
      type: Boolean,
    },
    doAuthorization: {
      required: true,
      type: Function,
    },
  },

  data () {
    return {
      notificationsVisible: false,
      isCursorOnIcon: false,
    };
  },

  computed: {
    ...mapState({
      unviewedNotificationsCount: (state: any) =>
        state.pusher.unviewedNotificationsCount,
      notificationsRequested: (state: any) => state.pusher.notificationsRequested,
      notifications: (state: any) => state.pusher.notifications,
    }),
    isEmptyVisible (): boolean {
      return this.notificationsRequested && this.notifications.length < 1;
    },
    bellClasses (): Record<string, boolean> {
      return {
        'header__bell--all-read': this.unviewedNotificationsCount < 1,
      };
    },
    unviewedNotificationsCountEdited (): string {
      const u = this.unviewedNotificationsCount;
      return u < 100 ? u : '99+';
    },
  },
  methods: {
    ...mapActions([
      FETCH_NOTIFICATIONS,
      RESET_UNVIEWED_NOTIFICATIONS_COUNT_ON_SERVER,
      EMPTY_NOTIFICATIONS,
      SET_NOTIFICATIONS_REQUESTED_ACTION,
    ]),
    handleNotificationsMouseEnter () {
      this.isCursorOnIcon = true;
      if (this.isAuthenticated) {
        this.showNotifications();
      }
    },
    handleNotificationsMouseLeave () {
      this.isCursorOnIcon = false;
      this.hideNotifications();
    },
    handleNotificationsClick () {
      if (this.isAuthenticated) {
        this.showNotifications();
      } else {
        this.doAuthorization();
      }
    },
    handleClickOutside () {
      this.hideNotifications();
    },
    showNotifications () {
      const resetBellCount = () => {
        this[RESET_UNVIEWED_NOTIFICATIONS_COUNT_ON_SERVER]();
      };

      if (!this.notificationsVisible) {
        if (this.notificationsRequested === false) {
          // * очищаем все уведомления
          this[EMPTY_NOTIFICATIONS]();
          // * меняем состояние notificationsRequested = true
          this[SET_NOTIFICATIONS_REQUESTED_ACTION]();
          return this[FETCH_NOTIFICATIONS]()
            .then(() => {
              if (
                !this.notificationsVisible &&
                this.unviewedNotificationsCount > 0
              ) {
                resetBellCount();
              }
              if (this.isCursorOnIcon) {
                this.notificationsVisible = true;
              }
            })
            .catch((err) => {
              this.$emit('fetch-notifications-error', err);
            });
        }
        if (!this.notificationsVisible && this.unviewedNotificationsCount > 0) {
          resetBellCount();
        }

        if (this.isCursorOnIcon) {
          this.notificationsVisible = true;
        }
      }
    },
    hideNotifications () {
      if (this.notificationsVisible) {
        this.notificationsVisible = false;
      }
    },
  },
});
