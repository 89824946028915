






































import Vue from 'vue';

type Reason = {
  code: string;
  comment: string;
  text: string;
}

export default Vue.extend({
  name: 'ModalDeleteAccountConfirm',

  data: () => ({
    title: 'Пожалуйста, укажите причину удаления вашего аккаунта',
    selectedReason: null as Reason | null,
    maxDeleteReasonCharsCount: 255,

    // TODO
    // для причин есть эндпоинт, можно использовать его
    // https://app.clickup.com/t/263h789?comment=750466188
    anotherReason: {
      code: '000',
      comment: 'Другая',
      text: '',
    },
    reasons: [
      {
        code: '001',
        comment: 'Мне не нравятся статьи',
        text: '',
      },
      {
        code: '002',
        comment: 'Я не нахожу сайт полезным',
        text: '',
      },
      {
        code: '003',
        comment: 'Меня беспокоит конфиденциальность',
        text: '',
      },
      {
        code: '004',
        comment: 'У меня есть другой аккаунт',
        text: '',
      },
      {
        code: '005',
        comment: 'Это временно. Я еще вернусь',
        text: '',
      },
      {
        code: '006',
        comment: 'Я провожу на сайте слишком много времени',
        text: '',
      },
      {
        code: '007',
        comment: 'Неудобно пользоваться сайтом',
        text: '',
      },
    ],
  }),

  computed: {
    allReasons () {
      return [
        ...this.reasons,
        this.anotherReason,
      ];
    },
    buttonDeleteClass () {
      const {
        selectedReason,
        anotherReason,
      } = this;

      let isDisabled = true;

      if (selectedReason) {
        if (selectedReason.code === anotherReason.code) {
          isDisabled = !selectedReason.text.trim().length;
        } else {
          isDisabled = false;
        }
      }

      return {
        'confirm__button--disabled': isDisabled,
      };
    },
  },

  watch: {
    selectedReason: {
      handler (val) {
        if (val && val.code === this.anotherReason.code) {
          (this.$refs.anotherReason as HTMLElement).focus();
        }
      },
      deep: true,
    },
  },

  methods: {
    cancelClick (): void {
      this.$emit('cancel');
    },

    deleteClick (): void {
      if (this.selectedReason?.code !== '000' ||
        (this.selectedReason?.code === '000' && this.selectedReason.text.length !== 0)
      ) {
        this.$emit('delete', this.selectedReason);
      }
    },

    focusAnotherReason () {
      this.selectedReason = this.anotherReason;
    },
  },
});
