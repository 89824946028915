











import Vue, { PropType } from 'vue';
import NewsWidgetItem from '~/components/NewsWidget/NewsWidgetItem.vue';

export default Vue.extend({
  name: 'NewsWidget',
  components: {
    NewsWidgetItem,
  },
  props: {
    widget: {
      type: Object as PropType<TWidget>,
      required: true,
    },
  },

  computed: {
    items (): IArticle[] {
      return this.widget.items as IArticle[];
    },
  },

});
