


















import Vue, { PropType } from 'vue';
import { getFullUrl, isAnotherOrigin } from '~/utils';

export default Vue.extend({
  name: 'TheFooterMenu',

  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },

    list: {
      type: Array as PropType<Array<FooterMenuItem>>,
      required: true,
    },
  },

  methods: {
    isAnotherOrigin,

    getAnalyticsLink (link: string): string {
      return getFullUrl(link);
    },

    onClickMenuItem (item: FooterMenuItem): void {
      this.$sendYandexMetrika({
        level1: 'Клик_футер',
        level4: getFullUrl(item.link),
        level5: item.title,
      });
    },

    footerMenuItemClass (menuItem: FooterMenu): any {
      const title = menuItem.title.trim().toLowerCase();
      const regex = /^\S[0-9A-Z_a-z]*$/g;

      return {
        [`footer-menu__item--${title}`]: regex.test(title),
      };
    },
  },
});
