import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';
import { IAction } from '~/types/store/delayed-actions/actions';
import {
  REMOVE_DELAYED_ACTION_ACTION,
  SET_DELAYED_ACTION_ACTION,
} from '~/constants/store/delayed-actions/actions';
import { AUTH_API_URL } from '~/constants/config';

function openWindowInPopup (url: string): Window | null {
  return window.open(
    url,
    'popUpWindow',
    'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes',
  );
}

const buttonsConfig = [
  // {
  //   method: 'facebook',
  //   title: 'Фейсбук*',
  // },
  {
    method: 'google',
    title: 'Google',
  },
  {
    method: 'vkontakte',
    title: 'ВКонтакте',
  },
  {
    method: 'apple',
    title: 'Apple',
  },
  {
    method: 'yandex',
    title: 'Яндекс',
  },
  {
    method: 'mailru',
    title: 'Почта Mail.Ru',
  },
];

const RESTRICTED_COUNTRY_ERROR_CODE = 451;

const authMethodToAnalyticsMap: { [key: string]: string } = {
  google: 'Гугл',
  vkontakte: 'Вконтакте',
  apple: 'Эппл',
  facebook: 'ФБ',
  mailru: 'Mail.ru',
  yandex: 'Яндекс Почта',
};

export default Vue.extend({
  name: 'LoginPage',
  props: {
    eventElement: {
      required: true,
      type: String as PropType<string>,
    },

    loginCallback: {
      type: Function as PropType<() => void>,
      default: undefined,
    },
  },
  data () {
    return {
      window: null as any,
      isRestrictedFromCountry: false as boolean,
      authMethodForAnalytics: '' as string,
    };
  },
  computed: {
    ...mapState({
      actions: (state: any) => state['delayed-actions'].actions,
      authMethodsRestricted: (state: any) => state.authMethodsRestricted,
    }),

    buttons () {
      return buttonsConfig;
    },

    headlineText (): string {
      return this.authMethodsRestricted ? 'Войдите или зарегистрируйтесь' : 'Войдите';
    },
  },

  async mounted () {
    window.addEventListener('message', ({ data }) => {
      try {
        // Проверяем что приходит объект с statusCode ошибки страны.
        if (
          typeof data === 'object' &&
          'statusCode' in data &&
          data.statusCode === RESTRICTED_COUNTRY_ERROR_CODE
        ) {
          this.isRestrictedFromCountry = true;
          this.window?.close();
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });

    const loginAction = this.actions.loginAction as IAction | undefined;
    if (loginAction) {
      await this.removeLoginAction();
    }

    await this.setLoginAction();
  },

  methods: {
    async removeLoginAction () {
      await this.$store.dispatch(`delayed-actions/${REMOVE_DELAYED_ACTION_ACTION}`, 'loginAction');
    },
    async setLoginAction () {
      await this.$store.dispatch(`delayed-actions/${SET_DELAYED_ACTION_ACTION}`, {
        name: 'loginAction',
        action: this.authCallback,
      });
    },
    windowOpen (socialNetwork: string) {
      this.authMethodForAnalytics = socialNetwork;
      this.window = openWindowInPopup(
        `${AUTH_API_URL}/id/auth/${socialNetwork}?display=popup&x-auth-app=web`,
      );
    },

    getMethodUrl (method: string) {
      return require(`~/assets/images/auth/${method}.svg`);
    },

    authCallback () {
      this.sendGTMEvent(this.authMethodForAnalytics);
      if (this.loginCallback) {
        this.loginCallback();
        this.removeLoginAction();
      }
      this.close();
    },
    close () {
      this.window?.close();
      this.removeLoginAction();
      this.$emit('close');
    },

    sendGTMEvent (socialNetwork: string) {
      this.$sendYandexMetrika({
        level1: 'Авторизация',
        level6: authMethodToAnalyticsMap[socialNetwork],
        level5: this.eventElement,
        level8: 'Авторизация',
      });
    },
  },
});
