











import Vue, { PropOptions } from 'vue';
// @ts-ignore
import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';

export default Vue.extend({
  name: 'TestPixel',

  components: {
    IntersectionObserver,
  },

  props: {
    src: {
      type: String,
      required: true,
    } as PropOptions<string>,
  },

  data: (): {
    isAppeared: boolean,
  } => ({
    isAppeared: false,
  }),
});
