














import Vue from 'vue';
import { isHealthPath } from '~/router';
import { getFullUrl } from '~/utils';
export default Vue.extend({
  name: 'TheHeaderAmp',

  computed: {
    isHealthPath (): boolean {
      return isHealthPath(this.$route.path);
    },
  },

  methods: {
    getAnalyticsLink (link: string): string {
      return getFullUrl(link);
    },
  },
});
