



































import Vue from 'vue';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { mapMutations, mapState } from 'vuex';
import { getHumanReadableDate, getNotificationLink } from '../utils.client';
import { SET_READ_TO_NOTIFICATION } from '~/constants';
import { getNotificationContent, getPhotoStyle } from '~/pages/ProfileNotifications/utils';
import { TNotification } from '~/store/pusher';

export default Vue.extend({
  name: 'BellNotifications',

  components: {
    PerfectScrollbar,
  },

  props: {
    notificationsVisible: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState({
      notifications: (state: any) => state.pusher.notifications,
      notificationsRequested: (state: any) => state.pusher.notificationsRequested,
    }),
    lastNotifications (): [TNotification] {
      return this.notifications.slice(0, 10);
    },
    isEmptyVisible (): boolean {
      return this.notificationsRequested && this.notifications.length < 1;
    },
    rootClasses (): Record<string, boolean> {
      return {
        'bell-notifications--empty': this.isEmptyVisible,
      };
    },
  },
  watch: {
    notificationsVisible (newVal) {
      if (newVal) {
        // Если элемент изначально не отображается, то скроллбар не может рассчитать свои размеры.
        // Поэтому при появлении элемента на экране нужно вызвать метод update.
        // См issue https://github.com/mdbootstrap/perfect-scrollbar/issues/476
        // и документацию библиотеки perfect-scrollbar
        try {
          // @ts-ignore
          this.$refs.perfectScrollbar.update();
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      }
    },
  },
  methods: {
    ...mapMutations({ SET_READ_TO_NOTIFICATION }),
    getPhotoStyle,
    getNotificationContent,
    getNotificationLink,
    getHumanReadableDate,
    getNotificationClasses (notification: TNotification) {
      const IS_READ = notification.is_read;
      return {
        'bell-notifications__item--is-read': IS_READ,
      };
    },
    showAllNotifications () {
      this.$emit('clickShowAllNotifications');
    },
    openNotification (notification: TNotification) {
      this[SET_READ_TO_NOTIFICATION](notification._id);
    },
    onClose () {
      this.$emit('close-notifications');
    },
  },
});
