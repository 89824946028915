
































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { createScriptIfNeeded } from '../../utils/embeds';
import MascotSpeech from './MascotSpeech.vue';

export default Vue.extend({
  name: 'MascotBurger',

  components: {
    MascotSpeech,
  },

  data () {
    return {
      animation: null as null | any,
      hovered: false as boolean,
      showSpeech: false as boolean,
      showFullMascot: false as boolean,
      hideMascot: true as boolean,
    };
  },

  computed: {
    ...mapGetters({
      mascot: 'mascotBurgerInfo',
    }),

    wrapperClasses (): Record<string, boolean> {
      return {
        'mascot-burger__wrapper--shown': this.showFullMascot,
        'mascot-burger__wrapper--closed': this.hideMascot,
      };
    },

    animationTransform (): Record<string, boolean> {
      return {
        'mascot-burger__lottie--hovered': this.hovered || this.showSpeech,
        'mascot-burger__lottie--full-show': this.showFullMascot,
      };
    },

    speechTransform (): Record<string, boolean> {
      return {
        'mascot-burger__speech--full-show': this.showFullMascot,
      };
    },
  },

  watch: {
    $route () {
      this.showSpeech = false;
      this.hideMascot = false;
      this.hovered = false;
    },
  },

  async mounted () {
    await createScriptIfNeeded('/js/lottie.min.js');

    const animationData = await import('~/lottie-animations/lazeyka-top-crop.json');

    this.animation = window?.lottie?.loadAnimation({
      container: this.$refs.mascotBurgerAnimation,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      animationData,
      initialSegment: [0, 450],
    });

    this.playAnimation();
    this.hideMascot = false;
  },

  methods: {
    playAnimation () {
      this.animation?.play();
      this.animation.loop = true;
    },

    closeSpeech () {
      this.hideMascot = true;
      this.$emit('close-mascot');
    },

    onClickMascot () {
      this.showSpeech = true;
      this.showFullMascot = true;
      this.playAnimation();
    },

    handleMouseover () {
      if (this.showSpeech || this.showFullMascot) {
        return;
      }

      if (this.animation.currentFrame < 350) {
        this.animation.pause();
      } else {
        this.animation.loop = false;
      }

      this.hovered = true;
    },

    handleMouseleave () {
      this.playAnimation();
      this.hovered = this.showSpeech ?? false;
    },
  },
});
