






import Vue, { PropType } from 'vue';

import ModalFade from '~/components/ModalFade.vue';
import LoginWindow from '~/components/LoginWindow/LoginWindow';

export default Vue.extend({
  name: 'LoginModal',
  components: {
    ModalFade,
    LoginWindow,
  },
  props: {
    eventElement: {
      required: true,
      type: String as PropType<string>,
    },

    loginCallback: {
      type: Function as PropType<() => void>,
      default: undefined,
    },
  },
  methods: {
    close (): void {
      this.$emit('close');
    },
  },

});
