









































import Vue from 'vue';
import { mapState } from 'vuex';
import closeSrc from './images/close-small.svg';
import errorSrc from './images/error_browser.svg';
import { REMOVE_BADGE } from '~/constants';

export default Vue.extend({
  name: 'TheBadges',

  computed: {
    ...mapState({
      badges: (state: any) => state.pusher.badges,
    }),
    closeSrc: () => closeSrc,
    errorSrc: () => errorSrc,
  },

  methods: {
    removeBadge (badgeId: number) {
      return this.$store.commit(REMOVE_BADGE, badgeId);
    },
    clickBadge ({ clickHandler, id }: { clickHandler: null | Function, id: number }) {
      if (clickHandler) { clickHandler(); }
      this.removeBadge(id);
    },
    getBadgeClasses ({ isError, clickHandler }: { isError: boolean, clickHandler: null | Function }) {
      return {
        'the-badges__item list-item': true,
        'the-badges__item--error': isError === true,
        'the-badges__item--no-cb': clickHandler === null,
      };
    },
  },
});
