// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./images/app-store.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./images/google-play.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer-menu{margin-bottom:16px;display:flex;flex-direction:column}@media(min-width:768px){.footer-menu{flex-direction:row}}@media(min-width:768px){.footer-menu,.footer-menu:not(:last-child){margin-bottom:0}}@media(min-width:1280px){.footer-menu:last-child .footer-menu__item{margin-bottom:0}}.footer-menu__title{font-family:PTRoot,Arial,sans-serif;font-weight:700;color:#000;font-size:16px;line-height:24px;margin-bottom:16px;color:#a0a2a7}@media(min-width:768px){.footer-menu__title{margin-right:24px}}@media(min-width:1280px){.footer-menu__title{margin-bottom:0}}.footer-menu__item{font-family:PTRoot,Arial,sans-serif;font-weight:700;color:#000;font-size:16px;line-height:24px;color:hsla(0,0%,100%,.8);text-decoration:none;margin-right:24px;white-space:normal}@media(min-width:768px){.footer-menu__item{white-space:nowrap}}@media(hover:hover){.footer-menu__item:hover{color:#fff}}.footer-menu__item--title{width:100%;font-family:PTRoot,Arial,sans-serif;font-weight:700;color:#000;font-size:16px;line-height:24px;color:#a0a2a7}@media(min-width:768px){.footer-menu__item--title{margin-right:24px;width:auto}}@media(min-width:1280px){.footer-menu__item--title{margin-bottom:0}}@media(hover:hover){.footer-menu__item--title:hover{color:#a0a2a7}}.footer-menu__item--android,.footer-menu__item--ios{position:relative;margin-left:23px}.footer-menu__item--android:before,.footer-menu__item--ios:before{position:absolute;content:\"\";width:18px;height:18px;top:3px;left:-23px}.footer-menu__item--ios:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.footer-menu__item--android:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.footer-menu__list{display:flex;flex-wrap:wrap;margin-bottom:0;grid-row-gap:16px;row-gap:16px}@media(min-width:768px){.footer-menu__list{grid-row-gap:8px;row-gap:8px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
